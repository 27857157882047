<template>
  <div class="sticky" style="top: 80px">
    <button
      type="button"
      class="w-full bg-white shadow-md border border-brand-grey-200 p-4 flex items-center"
      @click="toggleMobileNav"
    >
      <strong class="mr-2">Course type</strong>
      <span class="capitalize">{{
        selectedCourseType ? selectedCourseType.title : 'All'
      }}</span>
      <span class="capitalize mx-auto">{{
        selectedCourseType ? selectedCourseType.count : totalCourseCount
      }}</span>
      <Icon
        name="IconChevronDown"
        class="transform transition-all duration-200 text-black text-xl"
        :class="showMobileNav ? 'rotate-180' : ''"
        :title="
          showMobileNav ? 'hide course type filter' : 'show course type filter'
        "
      />
    </button>

    <div
      v-if="showMobileNav"
      class="absolute w-full bg-white shadow-md border border-brand-grey-200 py-2 overflow-y-scroll"
      style="top: 55px; max-height: calc(100vh - 180px)"
    >
      <div class="flex flex-col">
        <button
          class="block px-4 py-2 capitalize text-left"
          @click="courseTypeChanged(null)"
        >
          All <span class="ml-1">{{ totalCourseCount }}</span>
        </button>
        <button
          v-for="(item, key) in courseTypeFilters.sortedCourseTypes"
          :key="`btn_courseType_${key}`"
          class="block px-4 py-2 capitalize text-left"
          @click="courseTypeChanged(item)"
        >
          {{ `${item.title} ${item.count}` }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import useCourseTypeFilters, {
  CourseType
} from '~/composables/useCourseTypeFilters'

interface CourseTypeFilterMobileProps {
  courseTypes: [string, number][]
  courseAreaColour?: string
  selectedCourseType: CourseType | null
  totalCourseCount: number | null
}

const props = withDefaults(defineProps<CourseTypeFilterMobileProps>(), {
  courseAreaColour: '',
  selectedCourseType: null,
  totalCourseCount: null
})

const emit = defineEmits(['selected-course-type-changed'])

const courseTypeFilters = useCourseTypeFilters(props, courseTypeEmit)

function courseTypeChanged(item: CourseType | null | undefined) {
  showMobileNav.value = false
  courseTypeFilters.updateQuery(item)
  emit('selected-course-type-changed', item)
}

function courseTypeEmit(item: CourseType | null | undefined) {
  showMobileNav.value = false
  emit('selected-course-type-changed', item)
}

const showMobileNav = ref(false)

const toggleMobileNav = () => {
  showMobileNav.value = !showMobileNav.value
}
</script>
